@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Inter", sans-serif; */
  /* font-family: "Ubuntu", sans-serif; */
  /* font-family: "Kanit", sans-serif; */
  /* font-family: "Space Grotesk", sans-serif; */
  font-family: "Afacad Flux", sans-serif;
}

/* 
.map-container {
  height: 100%;
  width: 100%;
} */



.sect{
  background-image: url('./Components/Images/first-step-card-pattern-2.svg');
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.sect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: rgb(113, 80, 126);
  z-index: 1;
}

.sect1{
  background-image: url('./Components/Images/first-step-card-pattern-3.svg');
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.sect1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: rgb(8, 172, 136);
  z-index: 1;
}


.sect2{
  background-image: url('./Components/Images/first-step-card-pattern-1.svg');
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}



.sect2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: rgb(63, 100, 7);
  z-index: 1;
}


.bgUrl{
  background-image: url('https://res.cloudinary.com/dphb7gqus/image/upload/v1722103673/mypics/bdf_sn99o3.png');
  background-size: contain;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}


.bgUrlUser {
  position: relative;
  width: 100%;
  height: 55vh;
  background-image: url('./Components/Images/newUserBG.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .bgUrlUser {
    height: 50vh;
  }
}

.bgUrlUser::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.content{
  position: relative;
  z-index: 2;
  /* top: 50%;
  transform: translateY(-50%); */

}

.color{
  background-color: #0f1a0d;
}


@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-5px); /* Adjust the bounce height as needed */
  }
}

/* $small-One : 320px;
$small-Two : 480px;
$medium-One : 481px ;
$medium-Two : 768px;
$large-One : 1025px;
$large-Two : 1200px; */


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .bgUrl{
    background-image: none;
    height: fit-content;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1017px) {
  .bgUrl{
    background-image: none;
    height: fit-content;
  }
}



.react-multiple-carousel__arrow {
  min-width: 25px !important;
  min-height: 25px !important;
  bottom: 70px !important;
  z-index: 0 !important;
}


.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: -10 !important;
  position: relative;
}

.mycolor{
  color: rgb(2, 185, 152);
}

.mycolor2{
  background-color: rgb(2, 185, 152);
}

.bgNew{
  background-color: rgb(2, 114, 93);
}

.mycolor3{
  border: 1px solid rgb(2, 185, 152);
}

.mycolor4{
  background-color: rgb(2, 114, 93);
}


.mycolor2:hover{
  background-color: rgb(2, 114, 93);
}

.height{
  height: 75vh;
  max-width: 28%;
  width: 28%;
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
  .height{
    height: 80vh;
    max-width: 95%;
    width: 95%;
  }
}


@media only screen and (min-width: 481px) and (max-width: 1626px) {
  .height{
    height: 80vh;
    max-width: 50%;
    width: 50%;
  }
}

.width{
  width: 65%;
}



.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}


@media only screen and (min-width: 1025px) and (max-width: 1265px) {
  .res{
    height: 10rem;
  }
}




@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .myGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}


@media only screen and (min-width: 1023px) and (max-width: 1536px) {
  .myGridtwo{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }


}


@media only screen and (min-width: 1537px) and (max-width: 1625px) {
  .myGridthree{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}



.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  /* display: none !important; */
}




.swiper {
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}


/* .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 100;
  top: -100px !important;
} */


@media only screen and (min-width: 320px) and (max-width: 480px) {
  .swiper-button-prev:after, .swiper-button-next:after {
    display: none !important;
  }
}


.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 10px !important;
  font-weight: bold !important;;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  background-color: rgb(2, 158, 158);
  color: white !important;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 90% !important;

}

.steps .step {
  text-align: left !important;
}


.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 


  .loader2 {
    width: 48px;
    height: 48px;
    border: 3px solid #044964;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader2::after {
    content: '';  
    position: absolute;
    box-sizing: border-box;
    left: 20px;
    top: 31px;
    border: 10px solid transparent;
    border-right-color: #044964;
    transform: rotate(-40deg);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 


  .loader3 {
    width: 48px;
    height: 48px;
    border: 3px solid #044964;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader3::after {
    content: '';  
    position: absolute;
    box-sizing: border-box;
    left: 20px;
    top: 31px;
    border: 10px solid transparent;
    border-right-color: #044964;
    transform: rotate(-40deg);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 



  .loader4 {
    width: 43px;
    height: 43px;
    border: 4px solid #006446;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 



    .loadera {
      width: 100%;
      height: 250px;
      display: block;
      margin: auto;
      position: relative;
      background: #FFF;
      box-sizing: border-box;
      border: solid 1px rgb(219, 219, 219);
      border-radius: 5px;
    }
    .loadera::after {
      content: '';
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      top: 15px;
      left: 15px;
      position: absolute;
      background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
       radial-gradient(circle 40px , #DDD 100%, transparent 0),
       linear-gradient(#DDD 16px, transparent 0),
       linear-gradient(#DDD 24px, transparent 0);
      background-repeat: no-repeat;
      background-size: 75px 175px, 100% 100px, 80% 16px, 80% 16px;
      background-position: -185px 0, center 10px, center 125px, center 155px;
      box-sizing: border-box;
      animation: animloader 1s linear infinite;
    }
    
    @keyframes animloader {
      to {
        background-position: 185px 0, center 10px, center 125px, center 155px;
      }
    }
      


    .loader5 {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;
    }
    .loader5::after,
    .loader5::before {
      content: '';  
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #bd0606;
      position: absolute;
      left: 0;
      top: 0;
      animation: animloader 2s linear infinite;
    }
    .loader5::after {
      animation-delay: 1s;
    }
    
    @keyframes animloader {
      0% {
        transform: scale(0);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }



    .loaderx {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      display: inline-block;
      border-top: 4px solid black;
      border-right: 4px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    .loaderx::after {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border-bottom: 4px solid #740aca;
      border-left: 4px solid transparent;
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } 
        


    .loadery {
      position: relative;
      border: 24px solid black;
      border-radius: 50%;
      box-sizing: border-box;
      animation: eat 1s linear infinite;
    }
    .loadery::after , .loader::before {
      content: '';
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      background: #740aca;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-sizing: border-box;
      opacity: 0;
      animation: move 2s linear infinite;
    }

    .loadery::before {
      animation-delay: 1s;
    }

    @keyframes eat {
      0% , 49% { border-right-color: #740aca }
      50% , 100% { border-right-color: #0000 }
    }
    @keyframes move {
      0% { left: 75px ; opacity: 1}
      50% { left: 0px; opacity: 1 }
      52%, 100% { left: -5px; opacity: 0; }
    }